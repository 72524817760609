<template>
 <div><LinkButton @click="logout" iconCls="icon-man">退出</LinkButton></div>
</template>

<script setup>
import axios from 'axios';
function logout(){
  let arg={}
  arg.op="logout"
  axios.post('login',arg).then(row=>{
    console.log(row)
    if(row.result=='ok'){
      location.reload()
    }
  })
}
</script>
