<template>
  <ProgressBar :value="pbvalue" style="height:4px;"></ProgressBar>
  <img src="@/assets/loading.gif" style="width:30px; position: absolute; right: 0;top: 0;z-index: 1000;" />
</template>

<script setup>
import { ref } from 'vue';
const pbvalue=ref(0)
let interv=setInterval(() => {
  pbvalue.value+=1
  if(pbvalue.value>100)clearInterval(interv)
}, 100);
  
</script>

