<template>
    <DataGrid :key="gongchengs.total" :border="false" :clickToEdit="true" editMode="cell" @editEnd="onEditEnd"
        selectionMode="single" :data="gongchengs.data" @rowClick="rowClick" @rowContextMenu="rowContextMenu"
        :filterable="gongchengs.filterable" :multiSort="true" :columnMoving="true" :columnResizing="true"
        :pagination="gongchengs.filterable" :total="gongchengs.total"
        :pageSize="gongchengs.pageSize > 1 ? gongchengs.pageSize : settings.pageSize"
        :pageNumber="gongchengs.pageNumber > 1 ? gongchengs.pageNumber : settings.pageNumber"
        :pageList="gongchengs.pageList ? gongchengs.pageList : settings.pageList"
        :pageLayout="gongchengs.layout ? gongchengs.layout : settings.layout" @pageChange="onPageChange($event)">
        <GridColumn v-for="item in gongchengs.gridcolumn" :key="item" :field="item.field" :title="item.title"
            :editable="item.editable ? true : false">
        </GridColumn>
    </DataGrid>
    <Menu ref="m1">
        <MenuItem text="工程操作">
        <SubMenu>
            <MenuItem text="新增" @click="gongchengs.add" />
            <MenuItem text="修改" @click="gongchengs.edit" />
            <MenuItem text="复制" @click="gongchengs.fugou" />
            <MenuItem :text="'查看'+gongchengs.row.address+'资金明细'" @click="gongchengs.checksteps" />
            <MenuItem text="新增工程资金明细" @click="gongchengsteps.add" />
        </SubMenu>
        </MenuItem>
    </Menu>
</template>

<script setup>
import { gongchengs, settings,gongchengsteps } from '@/stores'
import { ref, } from 'vue'
// import GongchengsAddEdit from './GongchengsAddEdit.vue';
// import GongchengSteps from './GongchengSteps.vue'
const m1 = ref(null)
function onPageChange(event) {
    gongchengs.arg.page = gongchengs.pageNumber = event.pageNumber
    gongchengs.arg.rows = gongchengs.pageSize = event.pageSize
    //gongchengs.rows()
}
function rowClick(row) {
    if (gongchengs.row.id != row.id) {
        gongchengs.row = row
        // gongchengs.row.pics = decodeURIComponent(gongchengs.row.pics)
        // gongchengs.tabtitle = '修改' + gongchengs.row.address + '的工程明细'
        // gongchengs.selectedIndex = [1]
        // gongchengsteps.arg.gongchengid = gongchengs.row.id
        // gongchengsteps.rows()
        // gongchengsteps.add()
    }
}
function onEditEnd(item) {
    if (item.column.editable && item.originalValue != item.row[item.column.field]) {//确保修改过才执行
        settings.showbar = true
        let arg = { op: 'op', id: item.row.id }
        arg[item.column.field] = item.row[item.column.field]
        gongchengs.op(arg)
    }
}
function rowContextMenu(item) {
    rowClick(item.row)
    item.originalEvent.preventDefault()
    m1.value.showContextMenu(item.originalEvent.pageX, item.originalEvent.pageY)
}
</script>