<template>
  <DataGrid :key="records.total" :border="false"
    selectionMode="single" :data="records.data" @rowClick="rowClick"
    :filterable="records.filterable" :multiSort="true" :columnMoving="true" :columnResizing="true"
    :pagination="records.filterable" :total="records.total"
    :pageSize="records.pageSize > 1 ? records.pageSize : settings.pageSize"
    :pageNumber="records.pageNumber > 1 ? records.pageNumber : settings.pageNumber"
    :pageList="records.pageList ? records.pageList : settings.pageList"
    :pageLayout="records.layout ? records.layout : settings.layout" @pageChange="onPageChange($event)">
    <GridColumn v-for="item in records.gridcolumn" :key="item" :field="item.field" :title="item.title"
      :editable="item.editable ? true : false">
    </GridColumn>
  </DataGrid>
  <CkeditorComponent :data="records.row.content!=undefined?decodeURIComponent(records.row.content):''" @blur="onblur" />
</template>

<script setup>
import { records,settings } from '@/stores';
import CkeditorComponent from '../tools/CkeditorComponent.vue';
function onblur(data) {
  console.log(`output->`, data)
  // data = encodeURIComponent(data)
  // console.log(`output->`, data)
  let arg={content:data,id:records.row.id}
  records.op(arg)
}
function rowClick(row) {
    if (records.row.id != row.id) {
      records.row = row
      // records.row.content=decodeURIComponent(row.content)
    }
}
</script>