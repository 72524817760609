<template>
   <UsersComponent @click="click" />
   <div v-if="users.row.id">
      <RecordsComponent />
      <GongchengsComponent />
   </div>
</template>
<script setup>
import { gongchengs, users, records } from '@/stores';
import RecordsComponent from '@/components/records/RecordsComponent.vue';
import UsersComponent from '@/components/users/UsersList.vue';
import GongchengsComponent from '@/components/gongchengs/GongchengsComponent.vue';
function click() {
   //gongchengs.tabtitle='新增'+users.row.fullname+'的工程明细'
   records.arg.userid = users.row.id
   records.rows()
   gongchengs.add()
   if (gongchengs.gongchengtab) gongchengs.gongchengtab.select(0)
   gongchengs.arg.page = 1
   gongchengs.arg.userid = users.row.id
   gongchengs.rows()
}

</script>