<template>
    <div>
        <Accordion :key="gongchengs.selectedIndex" :animate="true" :multiple="true" :border="false"
            :selectedIndex="gongchengs.selectedIndex">
            <AccordionPanel>
                <template #header>
                    <div class="panel-title">基础设置</div>
                    <div class="right">
                        <span class="count">包含{{ gongchengs.gridcolumn.length }}项</span>
                    </div>
                </template>
                <div style="padding: 10px;">
                    <div v-for="item, index in gongchengs.gridcolumn" :key="index"
                        style="display:inline-block;margin-right:1em">
                        <Label for="t{{ index }}" align="top">{{ item.title }}：</Label>
                        <TextBox inputId="t{{ index }}" v-model="gongchengs.row[item.field]"
                            :placeholder="gongchengs.row[item.title]">
                        </TextBox>
                    </div>
                </div>
            </AccordionPanel>
            <AccordionPanel>
                <template #header>
                    <div class="panel-title">详细内容</div>
                    <div style="position: absolute;right: 24px; height: 31px;top:0px;">
                        <LinkButton iconCls="icon-clear" @click="gongchengs.add">清除</LinkButton>&nbsp;
                        <LinkButton iconCls="icon-save" @click="submit">提交保存</LinkButton>
                    </div>
                </template>
                <div style="padding: 10px;">
                    <Label align="top">施工步骤:</Label>
                    <!-- <ckeditor :editor="Editor" v-model="gongchengs.row.pics" :config="editorConfig" /> -->
                </div>
            </AccordionPanel>
        </Accordion>
    </div>
</template>

<script setup>
// import { reactive } from 'vue';
// import Editor from '@ckeditor/ckeditor5-build-inline';
import { gongchengs, } from '../../stores';
// import { SimpleUploadAdapter } from '@ckeditor/ckeditor5-upload';

// const editorConfig = reactive({
//     // plugins: [ SimpleUploadAdapter,  ],
//     simpleUpload: {
// 			uploadUrl: settings.uploadurl,
// 			// headers: {
// 			// 	'Content-Type': 'multipart/form-data',
// 			// },
//             //withCredentials: true
// 		},
//     // ckfinder: {
//     //     uploadUrl: settings.uploadurl,
//     //     data:{op:'upload'},
//     //     request:{op:'op'},
//     //     options:{
//     //         header:{'Content-Type': 'application/x-www-form-urlencoded'}
//     //     }
//     // },
//     // autosave: {
//     //     save() {
//     //         return saveData()
//     //     },
//     //     waitingTime: 3000,
//     // },
// })


function submit() {
    gongchengs.op(gongchengs.row)
}
</script>
<style>
.right {
    position: absolute;
    right: 24px;
    height: 30px;
    top: 5px;
}

.count {
    display: inline-block;
    border-radius: 6px;
    background: #666;
    color: #fff;
    padding: 0 6px;
    font-size: 12px;
    line-height: 16px;
    height: 16px;
}
</style>