import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { settings,companys } from './stores'
import 'v3-easyui/dist/themes/default/easyui.css'
import 'v3-easyui/dist/themes/icon.css'
import 'v3-easyui/dist/themes/vue.css'
import EasyUI from 'v3-easyui'
import locale from 'v3-easyui/dist/locale/easyui-lang-zh_CN'
import axios from 'axios'
import ckeditor from '@ckeditor/ckeditor5-vue'
axios.defaults.method = 'post'
axios.defaults.withCredentials = true //服务器也得支持这个才能行
axios.defaults.baseURL = 'https://login.xinyunyou.cn:8800/'
axios.defaults.headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
    //'Content-Type': 'text/plain'
}
axios.interceptors.request.use((config) => {
    if(!config.data.op)return Promise.reject('必须包含op参数')
    if (config.data.op == 'op') settings.showbar = true //新增修改的时候执行
    return config
})
axios.interceptors.response.use(res => {
    if (res.data.error) {
        if (res.data.error == 'nologin') {
            settings.login = {}
        }
        else if (res.data.error == 'nourl') {
            companys.row={}
            companys.errorinfo=res.data.errorinfo
        }
        settings.errorinfo = res.data.errorinfo
        //return { total: 0, rows: [] }
        //return false
        return Promise.reject(res.data.errorinfo)
        //throw new Error(res.data.errorinfo)
    }
    else {
        settings.showbar=false
        if(res.config.data.indexOf('op=op')>-1)settings.success() //新增修改的时候执行
        return res.data //直接输出json
    }
}), error => {
    console.log(error)
    //return false
    return Promise.reject(error)
}
createApp(App).use(router).use(EasyUI, { locale }).use(ckeditor).mount('#app')