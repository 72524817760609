<template>
  <div style="width: 100dh;text-align: center;">
    <div style="width:500px;height: 200px; margin: 30vh auto;">
      <div v-if="config.loading">
        <img src="@/assets/loading.gif" />
        <p style="height: 30px;line-height: 30px;">加载中请稍后......</p>
      </div>
      <Form v-else ref="form" :model="user" :rules="rules" labelAlign="right" :labelWidth="120" errorType="tooltip"
        @validate="errors = $event">
        {{ companys.row.brand }}<br /><br />
        <FormField name="mobile" label="手机号:">
          <TextBox v-model="user.mobile"></TextBox>
        </FormField>
        <FormField name="password" label="密码:">
          <PasswordBox v-model="user.password"></PasswordBox>
        </FormField>

        <FormField>
          <LinkButton :disabled="false" @click="submitForm()" style="width:120px" v-Tooltip="{content: settings.errorinfo,showEvent: 'click',position:'right'}">Submit</LinkButton>
        </FormField>
      </Form>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { settings } from '@/stores';
import axios from 'axios';
import { companys } from '../stores';
const form = ref(null)
// window.onkeydown=function(){
//   if(window.Event.keyCode==13){
//     submitForm()
//   }
// }
axios.post('login', { op: 'chk' }).then(row => {
  config.loading = false;
  if (row.id) {
    settings.showlogin = false
    settings.login = row
  }
})
const user = reactive({
  mobile: null,
  password: null,
})
const config = reactive({
  loading: true,
})
const rules = reactive({
  mobile: ["phone", "required", "length[11,11]"],
  password: ["required", "length[5,10]"],
})
const errors = reactive({})

function getError(name) {
  return errors[name] && errors[name].length ? errors[name][0] : null;
}
function hasError(name) {
  return getError(name) != null;
}
function submitForm() {
  form.value.validate((cc) => {
    hasError(cc)
    if (cc == null) {
      settings.errorinfo='登陆中......'
      user.op = "login"
      axios.post('login', user).then(row => {
        if (row.id) {
          settings.errorinfo='登陆成功'
          setTimeout(() => {
            settings.showlogin = false
          settings.login = row
          }, 1000);
          
        }
      })
    }
    else settings.errorinfo='必填项......'
  })
}
</script>
