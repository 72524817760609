import { reactive,markRaw } from 'vue'
import axios from 'axios'
import GongchengsAddEdit from '../components/gongchengs/GongchengsAddEdit.vue'
import GongchengSteps from '../components/gongchengs/GongchengSteps.vue'
import GongchengStepsAddEdit from '../components/gongchengs/GongchengStepsAddEdit.vue'

//这里放置的是设置，不改变
const settings = reactive({
  showbar: false,
  showsuccess: false,
  showlogin: true,
  login: {},
  errorinfo: '',
  pageSize: 10,
  pageNumber: 1,
  layout: ["list", 'first', 'prev', 'links', 'next', 'last', 'info'],
  pageList: [10, 30, 50, 100],
  uploadurl: 'http://nas.ixinya.cn:88/upload.php',
  success: function () {
    settings.showbar = false
    settings.showsuccess = true
    setTimeout(() => {
      settings.showsuccess = false
    }, 3000);
  }
})

const companys = reactive({
  row: {},
  errorinfo: '',
})
// const login = reactive({
//   row: {},
//   errorinfo: ''
// })
const homes = reactive({
  panels:[],
  selectedIndex:0,
  tabRef:null,
  addTab:function(panel){
    panel.component=markRaw(panel.component)
    for (let i = 0; i < homes.panels.length; i++) {
      if (homes.panels[i].component == panel.component) {
          homes.panels[i].title = panel.title
          homes.tabRef.select(i + 1)
          return
      }
  }
  // console.log(`output->`,homes.panels)
  // homes.panels.unshift(panel)
  homes.panels.splice(homes.selectedIndex,0,panel)
  setTimeout(() => {
      homes.tabRef.select(homes.selectedIndex+1)
  }, 100);
  }
})
const users = reactive({
  gridcolumn: [
    { field: 'mobile', title: '手机号', editable: "true", sortable: "true" },
    { field: 'fullname', title: '姓名', editable: "true", sortable: "true" },
    { field: 'funds', title: '余额', editable: "true" },
    { field: 'place', title: '位置' },
    { field: 'placedetail', title: '详细位置' },
    { field: 'adminname', title: '管理员', editable: "true" },
    { field: 'time', title: '合作时间', editable: "true" },
  ],
  selectedIndex:0,
  data: [],
  total: 0,
  row: {},
  filterable: false,
  pageSize: 10,
  pageNumber: 1,
  url: 'users',
  arg: { op: 'rows', page: 1, rows: 10 },
  rows: function () {
    users.row = {}
    axios.post(users.url, users.arg).then(res => {
      users.data = res.rows
      users.total = res.total
      if (res.total > users.pageSize) users.filterable = true
    })
  }
})//放置用户设置
const records = reactive({
  gridcolumn: [
    { field: 'content', title: '回访内容' },
    { field: 'time', title: '回访时间'},
  ],
  data: [],
  total: 0,
  row: {},
  filterable: false,
  pageSize: 10,
  pageNumber: 1,
  url: 'records',
  arg: { op: 'dginit', page: 1, rows: 10 },
  add: function () {
    records.row = {}
  },
   rows: function () {
    records.row = {}
    axios.post(records.url, records.arg).then(res => {
      if (res.total > records.pageSize) records.filterable = true
      records.data = res.rows
      records.total = res.total
    })
  },
  op: function (arg) {
    if (!arg.op) arg.op = 'op'
    if (arg.content) arg.content = encodeURIComponent(arg.content)
    if (!arg.usersid) arg.userid = users.row.id
    axios.post(records.url, arg).then(row => {
      if (records.row.id == undefined) {
        records.data.unshift(row)
        records.total++
      }
      records.row = row
      records.row.content=decodeURIComponent(records.row.content)
    })
  }
})//放置工程设置
const gongchengs = reactive({
  gridcolumn: [
    { field: 'address', title: '工程位置', editable: "true" },
    { field: 'area', title: '平方', editable: "true" },
    { field: 'total', title: '总金额', editable: "true" },
    { field: 'mobile', title: '甲方联系人', editable: "true" },
    { field: 'design', title: '原始设计', editable: "true" },
    { field: 'step', title: '施工步骤', editable: "true" },
  ],
  data: [],
  total: 0,
  row: {},
  filterable: false,
  pageSize: 10,
  pageNumber: 1,
  url: 'gongchengs',
  arg: { op: 'dginit', page: 1, rows: 10 },
  tabtitle: '新增',
  gongchengtab: null,
  selectedIndex: [0, 1],
  add: function () {
    gongchengs.row = {}
    gongchengs.selectedIndex = [0, 1]
    let panel={title:'新增' + users.row.fullname+'的工程',component:GongchengsAddEdit}
    // let panel={title:'新增' + users.row.fullname+'的工程',component:()=>import('../components/gongchengs/GongchengsAddEdit.vue')}
    homes.addTab(panel)
  },
  edit: function () {
    gongchengs.selectedIndex = [1]
    let panel={title:'修改' + gongchengs.row.address+'的工程',component:GongchengsAddEdit}
    homes.addTab(panel)
  },
  fugou: function () {
    let row = JSON.parse(JSON.stringify(gongchengs.row))
    row.id = ''
    delete row.time
    delete row.edittime
    gongchengs.row = row
    gongchengs.tabtitle = '复制并新增' + gongchengs.row.address
    gongchengs.selectedIndex = [0, 1]
  },
  checksteps:function(){
    gongchengsteps.arg.gongchengid=gongchengs.row.id
    gongchengsteps.rows()
    let panel={title: gongchengs.row.address+'的资金明细',component:GongchengSteps}
    homes.addTab(panel)
  },
  rows: function () {
    gongchengs.row = {}
    axios.post(gongchengs.url, gongchengs.arg).then(res => {
      if (res.total > gongchengs.pageSize) gongchengs.filterable = true
      gongchengs.data = res.rows
      gongchengs.total = res.total
      //console.log(`output->`,res)
    })
  },
  op: function (arg) {
    if (!arg.op) arg.op = 'op'
    if (arg.pics) arg.pics = encodeURIComponent(arg.pics)
    if (!arg.usersid) arg.userid = users.row.id
    axios.post(gongchengs.url, arg).then(row => {
     
      if (gongchengs.row.id == undefined) {
        gongchengs.data.unshift(row)
        gongchengs.total++
      }
      gongchengs.tabtitle = '修改' + gongchengs.row.address
      gongchengs.row = row
      gongchengs.row.pics = decodeURIComponent(gongchengs.row.pics)
    })
  }
})//放置工程设置
const gongchengsteps = reactive({
  gridcolumn: [
    { field: 'text', title: '款项名称', editable: "true" },
    { field: 'jine', title: '金额', editable: "true" },
    { field: 'fangshi', title: '打款方式', editable: "true" },
  ],
  data: [],
  total: 0,
  row: {},
  filterable: false,
  pageSize: 10,
  pageNumber: 1,
  url: 'gongchengsteps',
  arg: { op: 'dginit', page: 1, rows: 10 },
  selectedIndex: [0, 1],
  tabtitle: '新增',
  add: function () {
    gongchengsteps.row = {}
    gongchengsteps.selectedIndex = [0, 1]
    let panel={title:'新增' + gongchengs.row.address+'的明细',component:GongchengStepsAddEdit}
    // let panel={title:'新增' + users.row.fullname+'的工程',component:()=>import('../components/gongchengs/GongchengsAddEdit.vue')}
    homes.addTab(panel)
  },
  edit: function () {
    gongchengsteps.tabtitle = '修改'
    gongchengsteps.selectedIndex = [1]
    let panel={title:'修改' + gongchengs.row.address+'的'+gongchengsteps.row.text+'的明细',component:GongchengStepsAddEdit}
    // let panel={title:'新增' + users.row.fullname+'的工程',component:()=>import('../components/gongchengs/GongchengsAddEdit.vue')}
    homes.addTab(panel)
  },
  rows: function () {
    gongchengsteps.row = {}
    axios.post(gongchengsteps.url, gongchengsteps.arg).then(res => {
      if (res.total > gongchengsteps.pageSize) gongchengsteps.filterable = true
      gongchengsteps.data = res.rows
      gongchengsteps.total = res.total
    })
  },
  op: function (arg) {
    if (!arg.op) arg.op = 'op'
    if (arg.pics) arg.pics = encodeURIComponent(arg.pics)
    if (!arg.gongchengid) arg.gongchengid = gongchengs.row.id
    axios.post(gongchengsteps.url, arg).then(row => {
      //settings.success()
      if (gongchengsteps.row.id == undefined) {
        gongchengsteps.total++
        gongchengsteps.data.push(row)
      }
      gongchengsteps.tabtitle = '修改'
      gongchengsteps.row = row
      // console.log(`output->`,gongchengsteps.row.pics)
      gongchengsteps.row.pics = decodeURIComponent(row.pics)
      //console.log(`output->`,gongchengsteps.row.pics)
    })
  }
})//放置工程设置
const orders = reactive({
  gridcolumn: [
    { field: 'receipter', title: '收货人', editable: "true" },
    { field: 'mobile', title: '收货人手机', editable: "true" },
    { field: 'receipt', title: '发到哪里' },
    { field: 'total', title: '金额' },
    { field: 'paid', title: '实付金额' },
  ],
  data: [],
  total: 0,
  filterable: false,
  pageSize: 10,
  pageNumber: 1,
  arg: { op: 'rows', page: 1, rows: 10 },
  url: 'orders',
  row: {},
  rows: function () {
    axios.post(orders.url, orders.arg).then(res => {
      if (res.total > orders.pageSize) orders.filterable = true
      orders.data = res.rows
      orders.total = res.total
    })
  }
})
export { settings, users, gongchengs,  gongchengsteps, homes, orders, companys,records }