<template>
    <DataGrid :key="gongchengsteps.total" :border="false" :clickToEdit="true" editMode="cell" @editEnd="onEditEnd"
        selectionMode="single" :data="gongchengsteps.data" @rowClick="rowClick" @rowContextMenu="rowContextMenu"
        :filterable="gongchengsteps.filterable" :multiSort="true" :columnMoving="true" :columnResizing="true"
        :pagination="gongchengsteps.filterable" :total="gongchengsteps.total"
        :pageSize="gongchengsteps.pageSize > 1 ? gongchengsteps.pageSize : settings.pageSize"
        :pageNumber="gongchengsteps.pageNumber > 1 ? gongchengsteps.pageNumber : settings.pageNumber"
        :pageList="gongchengsteps.pageList ? gongchengsteps.pageList : settings.pageList"
        :pageLayout="gongchengsteps.layout ? gongchengsteps.layout : settings.layout"
        @pageChange="onPageChange($event)">
        <GridColumn v-for="item, index in gongchengsteps.gridcolumn" :key="index" :field="item.field"
            :title="item.title" :editable="item.editable ? true : false">
        </GridColumn>
    </DataGrid>

    <Menu ref="m1">
        <MenuItem text="新增" iconCls="icon-add" @click="add" />
        <MenuItem text="修改" iconCls="icon-edit" @click="edit" />
    </Menu>
</template>

<script setup>
import { gongchengsteps, settings } from '@/stores'
import { ref } from 'vue';
const m1 = ref(null)

function add() {
    gongchengsteps.add()
}
function edit() {
    gongchengsteps.edit()
}
function onPageChange(event) {
    gongchengsteps.arg.page = gongchengsteps.pageNumber = event.pageNumber
    gongchengsteps.arg.rows = gongchengsteps.pageSize = event.pageSize
}
function rowClick(row) {
    if (gongchengsteps.row.id != row.id) {
        gongchengsteps.row = row
        gongchengsteps.row.pics = decodeURIComponent(gongchengsteps.row.pics)
        // gongchengsteps.edit()
    }
}
function onEditEnd(item) {
    if (item.column.editable && item.originalValue != item.row[item.column.field]) {//确保修改过才执行
        // settings.showbar = true
        let arg = { op: 'op', id: item.row.id }
        arg[item.column.field] = item.row[item.column.field]
        gongchengsteps.op(arg)
    }
}
function rowContextMenu(item) {
    rowClick(item.row)
    item.originalEvent.preventDefault()
    m1.value.showContextMenu(item.originalEvent.pageX, item.originalEvent.pageY)
}
</script>