<template>
    <Accordion ref="acusers" :animate="true" :multiple="true" selectedIndex="0" :border="false">
        <AccordionPanel>
            <template #header>
                <div class="panel-title">客户信息</div>
                <div v-if="users.row.id" class="center">已选择客户：{{ users.row.placedetail }}的{{ users.row.fullname }}</div>
                <div class="right">
                    <span class="count">共{{ users.total }}个客户</span>
                </div>
            </template>
            <DataGrid ref="grid" :key="users.total" :filterable="users.total > users.pageSize"
                :pagination="users.total > users.pageSize" :multiSort="true" :columnMoving="true" :columnResizing="true"
                :border="false" selectionMode="single" :data="users.data" @rowClick="rowClick" :total="users.total"
                :pageSize="users.pageSize" :pageNumber="users.pageNumber"
                :pageList="users.pageList ? users.pageList : settings.pageList"
                :pageLayout="users.layout ? users.layout : settings.layout" @pageChange="onPageChange($event)"
                @rowContextMenu="rowContextMenu">
                <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template #body="scope">
                        {{ scope.rowIndex + 1 }}
                    </template>
                </GridColumn>
                <GridColumn :expander="true" width="30"></GridColumn>
                <GridColumn v-for="item, index in users.gridcolumn" :key="index" :field="item.field" :title="item.title"
                    :editable="item.editable ? true : false" :sortable="item.sortable ? true : false">
                </GridColumn>
                <template #detail="scope">
                    <div class="item f-row">
                        <div class="f-column">
                            <div class="item-desp f-full">管理员: {{ scope.row.adminname }}</div>
                            <div class="item-desp f-full">Status: {{ scope.row.status }}</div>
                        </div>
                    </div>
                </template>
                <template #group="scope">
                    <span style="font-weight:bold">
                        {{ scope.value }} - <span style="color:red">{{ scope.rows.length }}</span> 个
                    </span>
                </template>
            </DataGrid>
        </AccordionPanel>
    </Accordion>
    <Menu ref="m1">
        <!-- <MenuItem text="操作">
        <SubMenu>
            <MenuItem text="新增" />
        </SubMenu>
        </MenuItem> -->
        <MenuItem text="高级">
        <SubMenu>
            <MenuItem :text="'查看'+users.row.fullname+'的客户'" @click="users.arg.adminid=users.row.id;users.rows()" />
            <MenuItem :text="'查看'+companys.row.brand+'全公司客户'" @click="users.arg.companyid=companys.row.id;users.rows()" />
        </SubMenu>
        </MenuItem>
    </Menu>
</template>
<script setup>
import { settings, users } from '@/stores'
import { ref, defineEmits } from 'vue'
import { companys } from '../../stores';
const emit = defineEmits(['click'])
const grid = ref(null);
const acusers = ref(null)
const m1 = ref(null)
users.rows()
function onPageChange(event) {
    users.arg.page = users.pageNumber = event.pageNumber
    users.arg.rows = users.pageSize = event.pageSize
    users.row = {}
}
function rowClick(row) {
    // console.log(`output->`, grid)
    if (users.row.id != row.id) {
        users.row = row
        emit('click')
    }
    // else {
    //     users.row = {}
    // }
    acusers.value.unselect(0)
}
function rowContextMenu(item) {
    if (users.row.id != item.row.id) {
        users.row = item.row
        emit('click')
    }
    item.originalEvent.preventDefault()
    m1.value.showContextMenu(item.originalEvent.pageX, item.originalEvent.pageY)
}
</script>
<style>
.center {
    position: absolute;
    right: 10vw;
    height: 30px;
    line-height: 30px;
    top: 0;
    width: 80vw;
    text-align: center;
}

.right {
    position: absolute;
    right: 24px;
    height: 30px;
    top: 5px;
}

.count {
    display: inline-block;
    border-radius: 6px;
    background: #666;
    color: #fff;
    padding: 0 6px;
    font-size: 12px;
    line-height: 16px;
    height: 16px;
}
</style>