<template>
    <div>
        <Accordion :key="gongchengsteps.selectedIndex" :multiple="true" :border="false"
            :selectedIndex="gongchengsteps.selectedIndex" :animte="true">
            <AccordionPanel>
                <template #header>
                    <div class="panel-title">基础设置</div>
                    <div class="right">
                        <span class="count">包含{{ gongchengsteps.gridcolumn.length }}项</span>
                    </div>
                </template>
                <div style="padding: 10px;">
                    <div v-for="item, index in gongchengsteps.gridcolumn" :key="index"
                        style="display:inline-block;margin-right:1em">
                        <Label for="t{{ index }}" align="top">{{ item.title }}：</Label>
                        <TextBox inputId="t{{ index }}" v-model="gongchengsteps.row[item.field]"
                            :placeholder="gongchengsteps.row[item.title]">
                        </TextBox>
                    </div>
                    <div style="display:inline-block;margin-right:1em">
                        <LinkButton iconCls="icon-add" @click="insert">插入采购单</LinkButton>
                    </div>
                </div>
            </AccordionPanel>
            <AccordionPanel>
                <template #header>
                    <div class="panel-title">详细内容</div>
                    <div style="position: absolute;right: 24px; height: 31px;top:0px;">
                        <LinkButton iconCls="icon-clear" @click="gongchengsteps.add">清除</LinkButton>&nbsp;
                        <LinkButton iconCls="icon-save" @click="submit">提交保存</LinkButton>
                    </div>
                </template>
                <div style="padding: 10px;">
                    <Label align="top">打款明细照片支付证明等:</Label>
                    <!-- <ckeditor :editor="Editor" v-model="gongchengsteps.row.pics" :config="editorConfig" /> -->
                </div>
            </AccordionPanel>
        </Accordion>
    </div>
    <Dialog ref="dialoginsert" :title="'选择采购单'" :modal="true" :closed="true" :dialogStyle="{width:'800px',height:'400px'}">
        <OrdersComponent @click="doinsert" />
    </Dialog>
</template>

<script setup>
import { ref,  } from 'vue';
// import Editor from '@ckeditor/ckeditor5-build-inline';
import { gongchengsteps,orders,users, } from '@/stores';
import OrdersComponent from '@/components/orders/OrdersComponent.vue'
// import { SimpleUploadAdapter } from '@ckeditor/ckeditor5-upload';
const dialoginsert = ref(null)
// const editorConfig = reactive({
//     // plugins: [ SimpleUploadAdapter, /* ... */ ],
//     simpleUpload: {
// 			uploadUrl: settings.uploadurl,
// 			// headers: {
// 			// 	'Content-Type': 'multipart/form-data',
// 			// },
//             // withCredentials: true
// 		},
//     // ckfinder: {
//     //     uploadUrl: settings.uploadurl
//     // },
//     // autosave: {
//     //     save() {
//     //         return saveData()
//     //     },
//     //     waitingTime: 3000,
//     // },
// })
function insert() {
    orders.arg.userid=users.row.id
    orders.rows()
    dialoginsert.value.open()
}
function doinsert() {
    gongchengsteps.row.text='采购主材料'
    gongchengsteps.row.jine=-orders.row.chktotal
    gongchengsteps.row.fangshi='采购'
    gongchengsteps.row.orderid=orders.row.id
    gongchengsteps.row.pics='发到'+orders.row.receipt+',收货人:'+orders.row.receipter+',电话：'+orders.row.mobile
    dialoginsert.value.close()
}
function submit() {
    gongchengsteps.op(gongchengsteps.row)
}
</script>
<style>
.right {
    position: absolute;
    right: 24px;
    height: 30px;
    top: 5px;
}

.count {
    display: inline-block;
    border-radius: 6px;
    background: #666;
    color: #fff;
    padding: 0 6px;
    font-size: 12px;
    line-height: 16px;
    height: 16px;
}
</style>