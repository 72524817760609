<template>
  <div style="width: 100dh;text-align: center;">
    <div style="width:500px;height: 200px; margin: 30vh auto;">
      {{ companys.errorinfo }}
    </div>
  </div>
</template>

<script setup>
import { companys } from '@/stores';
import axios from 'axios';

axios.post('companys', { op: 'chk' }).then(row => {
  if (row.id) {
    companys.row = row
    document.title=row.brand+'管理系统'
  }
})

</script>
