<template>
  <ckeditor ref="geteditor" :editor="Editor" :model-value="props.data" :config="config" @blur="onblur">
  </ckeditor>
</template>
<!-- <script>
  import CKEditor from '@ckeditor/ckeditor5-vue';
  export default {
      name: 'app',
        components: {
            // Use the <ckeditor> component in this view.
            ckeditor: CKEditor.component
        },
  }
</script> -->
<script setup>
import { settings } from '@/stores';
import { reactive, defineProps, defineEmits, ref } from 'vue';
import Editor from '@ckeditor/ckeditor5-build-custominline';
const emit = defineEmits(['blur'])
const geteditor = ref(null)
const props = defineProps({
  data: String
})
const config = reactive({
  // simpleUpload: {
  //   uploadUrl: settings.uploadurl,
  //   // headers: {
  //   // 	'Content-Type': 'multipart/form-data',
  //   // },
  //   //withCredentials: true
  // },
  ckfinder: {
    uploadUrl: settings.uploadurl,
  }
})

function onblur() {
  // console.log(`output->`, geteditor.value)
  if (geteditor.value.lastEditorData != null)
    emit('blur', geteditor.value.lastEditorData)
}
</script>