<template>
    <DataGrid ref="grid" :key="orders.total" :filterable="orders.filterable" :multiSort="true" :columnMoving="true" :columnResizing="true"
        :border="false" selectionMode="single" :data="orders.data" @rowClick="rowClick" :pagination="orders.filterable"
        :total="orders.total" :pageSize="orders.pageSize > 1 ? orders.pageSize : settings.pageSize"
        :pageNumber="orders.pageNumber > 1 ? orders.pageNumber : settings.pageNumber"
        :pageList="orders.pageList ? orders.pageList : settings.pageList"
        :pageLayout="orders.layout ? orders.layout : settings.layout" @pageChange="onPageChange($event)">
        <GridColumn v-for="item in orders.gridcolumn" :key="item" :field="item.field" :title="item.title"
            :editable="item.editable ? true : false">
        </GridColumn>
    </DataGrid>
</template>

<script setup>
import { orders, settings } from '@/stores'
import { ref, defineEmits } from 'vue'
const grid = ref(null)
const emit = defineEmits(['click'])
function onPageChange(event) {
    orders.arg.page = orders.pageNumber = event.pageNumber
    orders.arg.rows = orders.pageSize = event.pageSize
    orders.row = {}
}
function rowClick(row) {
    if (orders.row.id != row.id) {
        orders.row = row
    }
    emit('click')
}
</script>