<template>
  <!-- <component :is="store.login.id==0?LoginView:AppView"></component> -->
  <!-- <div id="app"> -->
<NoCompanyView v-if="!companys.row.id" />
  <AppView v-else-if="settings.login.id" />
  <LoginView v-else />
<!-- </div> -->
</template>
<script setup>
import { settings,companys } from './stores'
import NoCompanyView from './views/NoCompanyView.vue'
import LoginView from './views/LoginView.vue'
import AppView from './views/AppView.vue'
// console.log(document.getElementsByClassName('tabs-container'))
// console.log(window)
window.DialogZIndex=900
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
